<template>
    <div class="ts-upload">
        <div v-if="file">
            <img :src="file" alt="">
        </div>
        <el-upload ref="upload" :action="action" :auto-upload="false" :multiple="false" :show-file-list="false"
                   :data="postData" :on-change="handleChange" :on-success="success" :on-error="failed"
                   :before-upload="beforeUpload">
            <el-button class="btn-blue" :loading="fileLoading">
                <template v-if="fileLoading">上传中...</template>
                <template v-else-if="file">更换图片</template>
                <template v-else>选择图片</template>
            </el-button>
        </el-upload>
    </div>
</template>
<script>
    import qiniu from '@/util/qiniu'
    export default {
        data() {
            return {
                fileLoading: false,
                action: qiniu.upload,
                postData: {
                    token: '',
                    key: '',
                },
                file: '',
            }
        },
        methods: {
            handleChange() {
                if(this.read || this.fileLoading) {
                    return
                }
                this.fileLoading = true
                qiniu.getToken().then(res => {
                    if(res.success){
                        this.postData.token = res.data
                        this.$refs.upload.submit();
                    }
                })
            },
            beforeUpload(file) {
                const name = file.name
                const splitStart = name.lastIndexOf('.')

                this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
            },
            success(obj) {
                this.file = obj.data.url
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },
            failed(obj) {
                console.log(obj)
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },
        }
    }
</script>